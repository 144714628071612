import axios from "axios"

const API = "configurar/peso-volumetrico";

const pesoVolumetrico = {
    listar: () => axios.get(`${API}/listar`),
    crearVolumen: data => axios.post(`${API}/crear-volumen`, data),
    editarVolumen: (id, data) => axios.put(`${API}/editar-volumen/${id}`, data),
    eliminarVolumen: id => axios.delete(`${API}/eliminar-volumen/${id}`),
    cambiarVolumen: data => axios.post(`${API}/cambiar-volumen`, data),
    
    crearImagen: data => axios.post(`${API}/crear-imagen`, data),
    editarImagen: data => axios.put(`${API}/editar-imagen`, data),
    eliminarImagen: id => axios.delete(`${API}/eliminar-imagen/${id}`),
    
    cambiarPosicion: data => axios.post(`${API}/cambiar-posicion`, data),
};

export default pesoVolumetrico;
