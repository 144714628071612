<template>
    <section>
        <div class="row mx-0 border-bottom py-2 px-3">
            <div class="col-auto text-general f-18 f-500">
                Peso Volumetrico
            </div>
        </div>
        <div class="row mx-0 align-items-center px-4 pt-4">
            <div class="col-auto f-18 text-general f-500">
                Lista de medidas
            </div>
            <div class="col-auto">
                <el-tooltip placement="bottom" content="Crear concepto de volumen" effect="light">
                    <div class="bg-general br-8 shadow d-middle-center text-white cr-pointer" style="width:32px;height:32px;" @click="medidaVolumetrica()">
                        <i class="icon-plus" />
                    </div>
                </el-tooltip>
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-3" style="height:calc(100vh - 204px)">
            <draggable :list="datos" handle=".handle" @change="cambiarPosicion">
                <div v-for="(data, i) in datos" :key="i" class="row mx-0 align-items-center px-4 mb-2">
                    <i class="icon-drag text-gris handle f-22 ml-2" />
                    <div class="col-8 px-1">
                        <div class="row mx-0 bg-light-f5 border br-4 py-1">
                            <div class="col-auto text-general f-15">
                                {{ data.nombre }}
                            </div>
                            <div class="col-auto px-2 text-general ml-auto">
                                Equivalencia: {{ data.volumen }}
                            </div>
                            <div class="col-auto">
                                <el-tooltip placement="bottom" content="Productos asociados a esta medida volumétrica" effect="light">
                                    <div class="bg-general3 text-white br-20 px-2">
                                        {{ data.productos_count }}
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto px-2">
                        <i class="icon-pencil-outline f-20 text-general cr-pointer" @click="editarMedida(data)" />
                    </div>
                    <div class="col-auto px-2">
                        <i class="icon-delete-outline f-20 text-general cr-pointer" @click="modalEliminarMedida(data)" />
                    </div>
                    <div v-show="data.imagenes.length < 5" class="col-auto px-2">
                        <i class="icon-add-image f-20 text-general cr-pointer" @click="cargarImagen(data)" />
                    </div>
                    <div class="col-12 pl-5 pt-3">
                        <div class="row mx-0">
                            <div v-for="(image, k) in data.imagenes" :key="k" class="card-image border br-8 position-relative mr-2 mb-2">
                                <div class="d-middle position-absolute" style="top:64px;left:20px">
                                    <div class="rounded-circle d-middle-center mx-1 bg-general3 text-white" style="width:20px;height:20px;">
                                        <i class="icon-pencil-outline f-14 cr-pointer" @click="editarImagen(image)" />
                                    </div>
                                    <div class="rounded-circle d-middle-center mx-1 bg-general3 text-white" style="width:20px;height:20px;">
                                        <i class="icon-delete-outline f-14 cr-pointer" @click="modalEliminarImagen(image)" />
                                    </div>
                                </div>
                                <img :src="image.imagen_firmada" width="100%" height="100%" class="obj-cover br-8" />
                            </div>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>
        <!-- Partials -->
        <modal-crear-medida-volumetrica ref="modalCrearMedida" @saved="listar()" />
        <modal ref="modalCargarImagen" :cargando="loading" :titulo="tituloImagen" tamano="modal-lg" no-aceptar adicional="Guardar" @adicional="handleImagen()">
            <div class="row mx-0 justify-center py-3">
                <slim-cropper ref="foto_medida" :options="slimOptions" class="border br-10 cr-pointer" style="height:346px;width:346px;background:#F8F9FF;" />
            </div>
        </modal>
        <modal-eliminar
        ref="eliminarImagen"
        :cargando="loading"
        titulo="Eliminar imagen"
        mensaje="¿Desea eliminar la imagen?"
        @eliminar="eliminarImagen()"
        />
        <modal-eliminar
        ref="eliminarMedida"
        :cargando="loading"
        titulo="Eliminar peso"
        mensaje="¿Desea eliminar este peso?"
        @eliminar="eliminarVolumen()"
        />
        <modal-eliminar
        ref="cambiarMedida"
        :cargando="loading"
        titulo="Eliminar peso"
        @eliminar="cambiarVolumen()"
        >
            <div class="row mx-0 justify-center mb-3">
                <div class="col-10">
                    <p class="p-2 text-center text-general">Esta medida tiene {{ medida.productos_count }} {{ medida.productos_count == 1 ? 'producto' : 'productos' }}</p>
                    <p class="p-2 text-center text-general">¿ Por cuál medida desea reemplazar ?</p>
                    <div class="row mx-0">
                        <div class="col-12 px-0">
                            <el-select v-model="cambiar" class="w-100 mt-3" size="small">
                                <el-option
                                v-for="(medid, i) in datos.filter(el => el.id != medida.id)"
                                :key="i"
                                :label="medid.nombre"
                                :value="medid.id"
                                />
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>
        </modal-eliminar>
    </section>
</template>

<script>

import pesoVolumetrico from '~/services/configurar/admin/pesoVolumetrico'

export default {
    components: {
        modalCrearMedidaVolumetrica: () => import('./partials/modalCrearMedidaVolumetrica')
    },
    data(){
        return {
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            datos: [],
            medida: {},
            imagen: {},
            tituloImagen: 'Cargar Imagen',
            editando: false,
            loading: false,
            cambiar: '',
        }
    },
    async mounted(){
        await this.listar()
    },
    methods: {
        medidaVolumetrica(){
            this.$refs.modalCrearMedida.toggle();
        },
        editarMedida(medida){
            this.$refs.modalCrearMedida.editar(medida);
        },
        modalEliminarImagen(imagen){
            this.imagen = imagen
            this.$refs.eliminarImagen.toggle();
        },
        modalEliminarMedida(medida){
            this.medida = medida
            this.cambiar = ''
            if(medida.productos_count == 0){
                this.$refs.eliminarMedida.toggle();
            } else {
                this.$refs.cambiarMedida.toggle();
            }
        },
        editarImagen(imagen){
            this.$refs.foto_medida.instanciaCrop.remove()
            this.editando = true
            this.tituloImagen = 'Editar Imagen'
            this.imagen = imagen
            this.$refs.foto_medida.set_image(`${imagen.imagen_firmada}`)
            this.$refs.modalCargarImagen.toggle();
        },
        cargarImagen(medida){
            this.$refs.foto_medida.instanciaCrop.remove()
            this.editando = false
            this.tituloImagen = 'Cargar Imagen'
            this.medida = medida
            this.$refs.modalCargarImagen.toggle();
        },
        async eliminarVolumen(){
            try {
                this.loading = true
                const { data } = await pesoVolumetrico.eliminarVolumen(this.medida.id)
                if (data.exito){
                    this.notificacion('', 'Peso eliminado correctamente', 'success')
                    this.medida = {}
                    await this.listar()
                    this.$refs.eliminarMedida.toggle();
                }
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        async handleImagen(){
            if (this.editando){
                await this.actualizarImagen()
            } else {
                await this.guardarImagen()
            }
        },
        async actualizarImagen(){
            try {
                this.loading = true
                const archivo = this.$refs.foto_medida.instanciaCrop.dataBase64.output
                let model = {
                    imagen: archivo.image,
                    id: this.imagen.id
                }

                const { data } = await pesoVolumetrico.editarImagen(model)
                if (data.exito){
                    this.notificacion('', 'Imagen actualizada correctamente', 'success')
                    this.imagen = {}
                    await this.listar()
                    this.$refs.foto_medida.instanciaCrop.remove()
                    this.$refs.modalCargarImagen.toggle();
                }
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        async cambiarVolumen(){
            try {
                this.loading = true
                const model = {
                    id: this.medida.id,
                    cambiar: !this.cambiar ? null : this.cambiar
                }
                const { data } = await pesoVolumetrico.cambiarVolumen(model)
                if (data.exito){
                    this.notificacion('', 'Peso eliminado correctamente', 'success')
                    this.imagen = {}
                    await this.listar()
                    this.$refs.cambiarMedida.toggle();
                }
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        async eliminarImagen(){
            try {
                this.loading = true
                const { data } = await pesoVolumetrico.eliminarImagen(this.imagen.id)
                if (data.exito){
                    this.notificacion('', 'Imagen eliminada correctamente', 'success')
                    this.imagen = {}
                    await this.listar()
                    this.$refs.eliminarImagen.toggle();
                }
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        async guardarImagen(){
            try {
                this.loading = true
                const archivo = this.$refs.foto_medida.instanciaCrop.dataBase64.output
                let model = {
                    imagen: archivo.image,
                    id: this.medida.id
                }

                const { data } = await pesoVolumetrico.crearImagen(model)
                if (data.exito){
                    this.notificacion('', 'Imagen guardada correctamente', 'success')
                    this.medida = {}
                    await this.listar()
                    this.$refs.foto_medida.instanciaCrop.remove()
                    this.$refs.modalCargarImagen.toggle();

                }
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        async listar(){
            try {
                const { data } = await pesoVolumetrico.listar()
                this.datos = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async cambiarPosicion(event){
            const obj = {
                antiguaPosicion: event.moved.oldIndex + 1,
                nuevaPosicion: event.moved.newIndex + 1,
            }
            const { data } = await pesoVolumetrico.cambiarPosicion(obj)
            if(data.exito){
                this.notificacion('', 'Posición cambiada correctamente', 'success')
                await this.listar()
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.card-image{
    width: 97px;
    height: 97px;
}
</style>
